

























































import Vue from 'vue';
import customConfirmationModal from '@/views/components/utilities/custom-confirmation-modal.vue';

interface ProfileCommunication {
  type: string,
  commentReactions: boolean,
  commentReplies: boolean,
  sessionReminder: boolean,
  checkUpReminder: boolean,
  goalReminder: boolean,
  webinarReminder: boolean,
  eventCompletion: boolean,
  exerciseCompletion: boolean,
  newsletter: boolean,
}

export default Vue.extend({
  name: 'comment-reply-banner',
  components: { customConfirmationModal },
  data() {
    return {
      showMarkRead: false,
      showHideReply: false,
      loading: false,
    };
  },
  computed: {
    user(): any {
      return this.$store.getters.user;
    },
    commentStatuses(): any {
      return this.$store.getters['comments/getCommentsStatuses'];
    },
    bannerTitle(): any {
      return this.commentStatuses.length > 1 ? this.$t('app.community.new_reply_banner.title_plural') : this.$t('app.community.new_reply_banner.title_singal');
    },
    replyButton(): any {
      return this.commentStatuses.length > 1 ? this.$t('app.community.new_reply_banner.hide_replies') : this.$t('app.community.new_reply_banner.hide_reply');
    },
    showCommentReplyBanner(): boolean {
      return this.commentStatuses?.some((status: any) => !status.read && !status.homeTileRead) && this.userInternalSettings?.commentReplies;
    },
    userInternalSettings(): any {
      return this.user.notifications.find((notification: any) => notification.type === 'INTERNAL');
    },
    internalSettings: {
      get(): ProfileCommunication {
        return this.user.notifications.find((notification: ProfileCommunication) => notification.type === 'INTERNAL');
      },
      set(newValue: ProfileCommunication): void {
        this.user.notifications = this.user.notifications.map((notification: ProfileCommunication) => {
          if (notification.type === 'INTERNAL') {
            return newValue;
          }
          return notification;
        });
      },
    },
  },
  mounted() {
    this.fetchStatuses();
  },
  methods: {
    onCardClick() {
      this.markReadConfirmed();
      this.$router.push({ name: 'profile-comments' });
    },
    toggleModal(viewName: string) {
      if (viewName === 'read') {
        this.showMarkRead = !this.showMarkRead;
        return;
      }
      if (viewName === 'hide') {
        this.showHideReply = !this.showHideReply;
      }
    },
    markReadConfirmed() {
      if (this.loading) return;
      this.loading = true;
      this.$store.dispatch('comments/markCommentHomeRead', this.commentStatuses).then(() => {
        this.$log.debug('Comment reply updated and markCommentHomeRead');
      }).finally(() => {
        this.showMarkRead = !this.showMarkRead;
        this.loading = false;
        this.fetchStatuses();
      });
    },
    hideReplyConfirmed() {
      this.updateCommunications();
    },
    fetchStatuses() {
      if (this.loading) return;
      this.loading = true;
      this.$store.dispatch('comments/getCommentsStatusesByUser').then(() => {
        this.$log.debug('Comment statuses called');
      }).finally(() => {
        this.$log.debug('Comment statuses refreshed');
        this.loading = false;
      });
    },
    updateCommunications() {
      if (this.loading) return;
      this.loading = true;
      this.userInternalSettings.commentReplies = !this.userInternalSettings.commentReplies;
      this.$store.dispatch('editUser', {}).then().finally(() => {
        this.loading = false;
        this.showHideReply = !this.showHideReply;
      });
    },
  },
});
