











































import Vue from 'vue';
import store from '@/scripts/store';
import { mapState } from 'vuex';
import { HomeStepTypeEnum, IHomeDailyProgress } from '@/scripts/store/modules/home/types';
import { IGoalSetter } from '@/scripts/store/modules/tools/goals/types';
import GoalCard from '@/views/tools/goals/components/goal-card.vue';
import HomeRecommendations from '@/views/home/steps/home-recommendations.vue';
import IntroductionCarousel from '@/views/home/components/introduction-carousel.vue';
import MemberIdModal from '@/views/home/components/member-id-modal.vue';
import StarlingLogoColors from '@/views/images/starling-logo-colors.vue';
import CommentReplyBanner from '@/views/community/components/comment-reply-banner.vue';
import HomeTrackProgress from '@/views/home/steps/home-track-progress.vue';
import { ProfileCommunication } from '@/views/profile/partials/profile-communication.vue';
import HomeHiddenWidgets from '@/views/home/steps/home-hidden-widgets.vue';

export default Vue.extend({
  name: 'home-view',
  components: {
    HomeHiddenWidgets,
    HomeTrackProgress,
    GoalCard,
    HomeRecommendations,
    IntroductionCarousel,
    MemberIdModal,
    StarlingLogoColors,
    CommentReplyBanner,
  },
  data() {
    return {
      loading: false,
      dailyProgress: null as IHomeDailyProgress | null,
      showIntroduction: false,
      introductionSteps: null,
      pinnedGoal: undefined as IGoalSetter | undefined,
    };
  },
  computed: {
    loadingView(): boolean {
      return this.$store.state.loadingView;
    },
    ...mapState({
      partner: (state: any) => {
        return state.identity;
      },
    }),
    user(): any {
      return this.$store.getters.user;
    },
    internalSettings(): ProfileCommunication {
      return this.user.notifications.find((notification: ProfileCommunication) => notification.type === 'INTERNAL');
    },
    commentStatuses(): any {
      return this.$store.getters['comments/getCommentsStatuses'];
    },
    hasCommentReplies(): boolean {
      return this.commentStatuses?.some((status: any) => !status.read && !status.homeTileRead);
    },
    hasHiddenFeatures(): boolean {
      return (this.hasCommentReplies && this.hiddenCommentReplies) || this.hiddenCheckupReminder;
    },
    hiddenCommentReplies(): boolean {
      return !this.internalSettings.commentReplies;
    },
    hiddenCheckupReminder(): boolean {
      return !this.internalSettings.checkUpReminder;
    },
  },
  beforeRouteLeave(to, from, next) {
    store.commit('navigation/displayLogo', false);
    next();
  },
  mounted() {
    this.$store.commit('setLoadingView', true);
    Promise.all([ this.$store.dispatch('home/getDailyProgress'), this.$store.dispatch('goals/getPinnedGoal'), this.$store.dispatch('myStarling/getMilestones') ]).then((res) => {
      const dailyProgress = res[0] as IHomeDailyProgress;
      this.dailyProgress = dailyProgress;
      if (dailyProgress.introduction) {
        this.$store.dispatch('home/getIntroduction').then(introductionSteps => {
          this.introductionSteps = introductionSteps;
          this.showIntroduction = introductionSteps && introductionSteps.length;
        });
      }

      const goal = res[1] as IGoalSetter;
      this.pinnedGoal = goal && goal.id ? goal : undefined;

    }).finally(() => {
      this.$log.debug('Loaded homepage');
      this.$store.commit('setLoadingView', false);
    });
  },
  methods: {
    closeIntroductionPopup() {
      this.$store.dispatch('home/completeDailyStep', HomeStepTypeEnum.INTRODUCTION).finally(() => {
        this.showIntroduction = false;
      });
    },
    onEditGoal() {
      if (!this.pinnedGoal) return;
      this.$router.push({ name: 'goal-edit', params: { id: this.pinnedGoal.id as string } });
    },
  },
});
