var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loadingView
    ? _c(
        "v-layout",
        { staticClass: "home-view", attrs: { row: "", wrap: "", "mt-3": "" } },
        [
          [_c("comment-reply-banner")],
          _vm.pinnedGoal && _vm.pinnedGoal.pinned
            ? [
                _c(
                  "v-flex",
                  {
                    class: { "first-header": _vm.$vuetify.breakpoint.mdAndUp },
                    attrs: { xs12: "", "mb-3": "" }
                  },
                  [
                    _c("h1", { staticClass: "starling-h1" }, [
                      _vm._v(
                        _vm._s(_vm.$t("app.home.subheader.progress_title"))
                      )
                    ])
                  ]
                ),
                _c(
                  "v-flex",
                  { attrs: { xs12: "", "mb-3": "" } },
                  [
                    _c("goal-card", {
                      attrs: {
                        goal: _vm.pinnedGoal,
                        showHeader: true,
                        embedded: true
                      },
                      on: {
                        edit: _vm.onEditGoal,
                        update: function($event) {
                          _vm.pinnedGoal = $event
                        }
                      }
                    })
                  ],
                  1
                )
              ]
            : _vm._e(),
          _vm.dailyProgress
            ? [
                _c(
                  "v-flex",
                  {
                    class: {
                      "first-header":
                        _vm.$vuetify.breakpoint.mdAndUp &&
                        (!_vm.pinnedGoal || !_vm.pinnedGoal.pinned)
                    },
                    attrs: { xs12: "" }
                  },
                  [
                    _c("h1", { staticClass: "starling-h1" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("app.home.subheader.recommendation_title")
                        )
                      )
                    ])
                  ]
                ),
                _c("v-flex", { attrs: { xs12: "", "mb-3": "" } }, [
                  _c("p", { staticClass: "starling-body" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("app.home.subheader.recommendation_subtitle")
                      )
                    )
                  ])
                ]),
                _c(
                  "v-flex",
                  { attrs: { xs12: "", "mb-3": "" } },
                  [_c("home-recommendations")],
                  1
                )
              ]
            : _vm._e(),
          _vm.partner &&
          _vm.partner.type === "COLABELLED" &&
          _vm.$vuetify.breakpoint.xsOnly
            ? _c("v-flex", { attrs: { xs12: "" } }, [
                _c(
                  "div",
                  { staticClass: "text-center" },
                  [
                    _c(
                      "p",
                      { staticClass: "starling-italic-text text--primary" },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("common.partnership.powered_by")) +
                            " "
                        )
                      ]
                    ),
                    _c("starling-logo-colors", {
                      staticStyle: { "max-width": "100px" }
                    })
                  ],
                  1
                )
              ])
            : _vm._e(),
          this.$store.getters.user ? _c("member-id-modal") : _vm._e(),
          _vm.introductionSteps && _vm.introductionSteps.length
            ? _c(
                "v-dialog",
                {
                  attrs: {
                    "max-width": _vm.$vuetify.breakpoint.xsOnly
                      ? "98%"
                      : "800px",
                    "content-class": "introduction-dialog radius-15",
                    light: "",
                    persistent: ""
                  },
                  model: {
                    value: _vm.showIntroduction,
                    callback: function($$v) {
                      _vm.showIntroduction = $$v
                    },
                    expression: "showIntroduction"
                  }
                },
                [
                  _c("introduction-carousel", {
                    attrs: { steps: _vm.introductionSteps },
                    on: { close: _vm.closeIntroductionPopup }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }