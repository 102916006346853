














































import Vue from 'vue';
import store from '@/scripts/store';
import { mapState } from 'vuex';
import { HomeStepTypeEnum, IHomeDailyProgress } from '@/scripts/store/modules/home/types';
import { IGoalSetter } from '@/scripts/store/modules/tools/goals/types';
import GoalCard from '@/views/tools/goals/components/goal-card.vue';
import HomeRecommendations from '@/views/home/steps/home-recommendations.vue';
import IntroductionCarousel from '@/views/home/components/introduction-carousel.vue';
import MemberIdModal from '@/views/home/components/member-id-modal.vue';
import StarlingLogoColors from '@/views/images/starling-logo-colors.vue';
import CommentReplyBanner from '@/views/community/components/comment-reply-banner.vue';

export default Vue.extend({
  name: 'home-view',
  components: {
    GoalCard,
    HomeRecommendations,
    IntroductionCarousel,
    MemberIdModal,
    StarlingLogoColors,
    CommentReplyBanner,
  },
  data() {
    return {
      dailyProgress: null as IHomeDailyProgress | null,
      showIntroduction: false,
      introductionSteps: null,
      pinnedGoal: undefined as IGoalSetter | undefined,
    };
  },
  computed: {
    loadingView(): boolean {
      return this.$store.state.loadingView;
    },
    ...mapState({
      partner: (state: any) => {
        return state.identity;
      },
    }),
  },
  beforeRouteLeave(to, from, next) {
    store.commit('navigation/displayLogo', false);
    next();
  },
  mounted() {
    this.$store.commit('setLoadingView', true);
    this.$store.dispatch('home/getDailyProgress').then((res: IHomeDailyProgress) => {
      this.dailyProgress = res;
      if (res.introduction) {
        this.$store.dispatch('home/getIntroduction').then(introductionSteps => {
          this.introductionSteps = introductionSteps;
          this.showIntroduction = introductionSteps && introductionSteps.length;
        });
      }
      this.$store.dispatch('goals/getPinnedGoal').then((goal: IGoalSetter) => {
        this.pinnedGoal = goal.id ? goal : undefined;
      });
    }).finally(() => {
      this.$log.debug('Loaded homepage');
      this.$store.commit('setLoadingView', false);
    });
  },
  methods: {
    closeIntroductionPopup() {
      this.$store.dispatch('home/completeDailyStep', HomeStepTypeEnum.INTRODUCTION).finally(() => {
        this.showIntroduction = false;
      });
    },
    onEditGoal() {
      if (!this.pinnedGoal) return;
      this.$router.push({ name: 'goal-edit', params: { id: this.pinnedGoal.id as string } });
    },
  },
});
