import { render, staticRenderFns } from "./home-checkup-chosen-date.vue?vue&type=template&id=52dc807f&scoped=true&"
import script from "./home-checkup-chosen-date.vue?vue&type=script&lang=ts&"
export * from "./home-checkup-chosen-date.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52dc807f",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VDatePicker,VDialog,VIcon,VSpacer})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src551672837/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('52dc807f')) {
      api.createRecord('52dc807f', component.options)
    } else {
      api.reload('52dc807f', component.options)
    }
    module.hot.accept("./home-checkup-chosen-date.vue?vue&type=template&id=52dc807f&scoped=true&", function () {
      api.rerender('52dc807f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/home/steps/home-checkup-chosen-date.vue"
export default component.exports